/* global grecaptcha */
import React, { Component } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { auth } from '../assets/services/firebase';
import axios from 'axios';
import logo from '../assets/media/logo.png';
import login01 from '../assets/media/login01.png';
import flags from 'react-phone-number-input/flags'
import '../assets/styles/login.css';

class Login extends Component {

	state = {
		step: 1,
		myNumber: '372',
		code: [],
		final: '',
		value: ''
	}

	async componentDidMount() {
			const res = await axios.get('https://geolocation-db.com/json/')
			console.log(res.data.country_code);
	}

	signin() {
		const { myNumber, step } = this.state;
		if (myNumber === "" || myNumber.length < 10) return;
    const phoneNumber = '+' + myNumber;
		const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {'size': 'invisible'}, auth);

    const appVerifier = recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
					this.setState({final: confirmationResult, step: step + 1 })
        }).catch((error) => {
          alert(error)
					appVerifier.clear()
        });
	}

	handleChange = (event) => {
    let result = event.target.value.replace(/\D/g, '');
		this.setState({myNumber: result})
  }

	// Validate OTP
	validateOtp = () => {
		const { code, final } = this.state;
		const shopID = window.localStorage.getItem('pid')
		if (code === null || final === null)
			return;
		final.confirm(code).then((result) => {
			window.location = '/?pid=' + shopID
		}).catch((err) => {
			alert("Wrong code");
			console.log(err)
		})
	}

  handleCode = (event) => {
		const { code } = this.state;
		const form = event.target.form;
		const index = [...form].indexOf(event.target);
		let number = code
    if (event.target.value.length == 1) {
			if(index < 5) {
				form.elements[index + 1].focus();
			}
      event.preventDefault();
			number[index] = event.target.value
			this.setState({code: number})
			if(number.length == 6) {
				this.setState({code: number.join("")}, function() {
					this.validateOtp()
				})
			}
    }
	}

	handleKeyDown = (event) => {
		const { code } = this.state;
		if(event.key.toLowerCase() === 'backspace') {
			const form = event.target.form;
			const index = [...form].indexOf(event.target);
			let number = code
			form.elements[index - 1].value = '';
			form.elements[index - 1].focus();
			event.preventDefault();
			number.pop()
			this.setState({code: number})
		}
	}

	render() {

		const { step, value } = this.state;
		if(step == 0) {
			return (
				<div class='loginWrapper'>
					<img src={logo} class='logoImage' alt='TableSmart'/>
					<div class='loginInfo'>
						<h4>TableSmart on mõeldud lihtsustamaks tellimist ning tasumist toitlustusasutustes. Esita tellimus vaid mõne klikiga ning saad koheselt ka tellimuse eest tasuda. TableSmart teeb lauaarve jagamise lihtsamaks - iga liige tellib ja tasub oma tellimuse eest ise.</h4>
					</div>
					<div class='loginImageWrapper'>
						<img src={login01} class='loginImage' alt='TableSmart'/>
					</div>
					<div class='loginButton' onClick={() => this.setState({step: this.state.step + 1})}>
						<h2 class='white'>Edasi</h2>
					</div>
				</div>
			)
		}

		if(step == 1) {
			return (
				<div class='loginWrapper column'>
					<script src="https://www.google.com/recaptcha/api.js" async defer></script>
					<div class='loginHeader'>
						<h1>Sisenemiseks tuleb ennast tuvastada telefoninumbiga</h1>
						<h3>Millise telefoninumbri soovid TableSmart'iga siduda?</h3>
					</div>
					<div class='phoneInputWrapper'>
						<input class='phoneInput' type="tel" value={this.state.myNumber} onChange={this.handleChange}/>
						<h4>Telefonile saadetakse SMS kontrollkoodiga</h4>
					</div>
					<div id="recaptcha-container" class='captcha'></div>
					<div class='loginButton' onClick={() => this.signin()}>
						<h2 class='white'>Edasi</h2>
					</div>
				</div>
			)
		}

		if(step == 2) {
			return (
				<div class='loginWrapper column'>
				<div id="recaptcha-container" class='captcha'></div>

					<div class='loginHeader'>
						<h1>Sisesta telefonile saabunud 6-kohaline kood</h1>
						<h4>Sõnumi saabumine võib võtta mõne hetke</h4>
					</div>
					<form class='phoneInputWrapper code'>
						<input class='phoneInput code' maxLength='1' type="tel" onChange={this.handleCode}/>
						<input class='phoneInput code' maxLength='1' type="tel" onKeyDown={() => this.handleKeyDown} onChange={this.handleCode}/>
						<input class='phoneInput code' maxLength='1' type="tel" onKeyDown={() => this.handleKeyDown} onChange={this.handleCode}/>
						<input class='phoneInput code' maxLength='1' type="tel" onKeyDown={() => this.handleKeyDown} onChange={this.handleCode}/>
						<input class='phoneInput code' maxLength='1' type="tel" onKeyDown={() => this.handleKeyDown} onChange={this.handleCode}/>
						<input class='phoneInput code' maxLength='1' type="tel" onKeyDown={() => this.handleKeyDown} onChange={this.handleCode}/>
					</form>

				</div>
			)
		}

	}
}

export default Login;
