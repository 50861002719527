import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ref, query, onValue, update } from "firebase/database";

import { auth, db , userIdentity} from '../assets/services/firebase';
import { date, time } from '../assets/services/dateTime';

import UserMenu from '../components/userMenu';
import Header from '../components/header';
import Loader from '../components/loader';

class AccountScreen extends Component {


  state = {
    isLoading: false,
    archive: [],
    age: 2022-16,
    gender: -1,
    email: '',
    location: '',
    name: ''
  }

  handleChange = (event) => {
    let result = event.target.value.replace(/\D/g, '');
		this.setState({age: result})
  }

  componentDidMount() {
    const req = query(ref(db, 'users/' + userIdentity()))
    onValue(req, (snap) => {
      this.setState({
        name: snap.val().name,
        gender: snap.val().gender,
        email: snap.val().email,
        location: snap.val().location,
        age: snap.val().age,
      })
    })
  }

  saveProfileData() {
    const { name, gender, email, location, age } = this.state;
    update(ref(db, 'users/' + userIdentity()), {
      editDate: date,
      editTime: time,
      name: name,
      gender: gender,
      email: email,
      location: location,
      age: age,
      phone: auth.currentUser.phoneNumber
    })
  }

  render() {

    const { isLoading, arcive, name, gender, age, location, email } = this.state;
    const { menuState } = this.props;

    if(isLoading) {
      return (
        <Loader />
      )
    }

    return (
      <div class='mainContainer'>
        {menuState && <UserMenu />}
        <Header />
        <div class='mainContainer'>
          <div class='registrationFormContainer'>
            <div class='columnHeaderContainer'>
              <h2 class='red'>Konto</h2>
              <h4>Salvesta enda andmed ning võid võita erinevaid auhindu ning saada spetsiaalseid eripakkumisi, mis mõeldud ainult kontaktandmed sisestanud klientidele</h4>
            </div>
            <div class='column'>
              <h2>Ees- ja perekonnanimi</h2>
              <input class='input' value={name} onChange={(event) => this.setState({name: event.target.value})}/>
            </div>
            <div class='column'>
              <h2>Sugu</h2>
              <div class='row'>
                <div class={gender == 0 ? 'optionSelection selected' : 'optionSelection'} onClick={() => this.setState({gender: 0})}>
                  <h2 class={gender == 0 ? 'white' : 'red'}>Mees</h2>
                </div>
                <div class={gender == 1 ? 'optionSelection selected' : 'optionSelection'} onClick={() => this.setState({gender: 1})}>
                  <h2 class={gender == 1 ? 'white' : 'red'}>Naine</h2>
                </div>
              </div>
            </div>
            <div class='column'>
              <h2>E-posti aadress</h2>
              <input class='input' value={email} onChange={(event) => this.setState({email: event.target.value})}/>
            </div>
            <div class='column'>
              <h2>Elukoht</h2>
              <input class='input' value={location} onChange={(event) => this.setState({location: event.target.value})}/>
            </div>
            <div class='column'>
              <h2>Sünniaasta</h2>
              <input class='input small' value={age} maxLength='4' onChange={this.handleChange}/>
            </div>
            <div class='fullBtn up' onClick={() => this.saveProfileData()}>
              <h1 class='white'>Salvesta</h1>
            </div>
            <div class='fullBtn red' onClick={() => auth.signOut()}>
              <h1 class='white'>Logi välja</h1>
            </div>
          </div>

        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    menuState: state.mainReducers.menuState
  }
}

export default connect(mapStateToProps)(AccountScreen)
