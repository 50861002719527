  import React, { Component } from 'react';
import jwt_decode from "jwt-decode";
import { connect } from 'react-redux';

import UserMenu from '../components/userMenu';
import Header from '../components/header';

import cooking from '../assets/media/cooking.png'
import logo from '../assets/media/logo.png';
import menu from '../assets/media/menu.png';

class CompletedScreen extends Component {

  render() {

    const { menuState } = this.props;

    const queryParams = new URLSearchParams(window.location.search);
    const payment_token = queryParams.get('payment_token');
    var decoded = jwt_decode(payment_token);

    return (
      <div class='mainContainer'>
        <img src={logo} class='logoImage'/>
        <div class='mainContainer'>
          {menuState && <UserMenu />}

          <Header />

          {decoded.status === 'finalized' ?
          <div class='infoContainer'>
            <img src={cooking} class='bigImage'/>
            <h1>Tellimus on kinnitatud</h1>
            <h4>Teie tellimust hakati valmistama ja see jõuab peagi teieni</h4>
          </div>
          :
          <div class='infoContainer center'>
            <h1>Maksmine katkestatud</h1>
            <h4>Midagi läks valesti ning tellimus ei ole kinnitatud.</h4>
          </div>
          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    menuState: state.mainReducers.menuState
  }
}

export default connect(mapStateToProps)(CompletedScreen)
