import { createStore, combineReducers } from 'redux';
import mainReducers from './reducers/mainReducers';

const rootReducer = combineReducers({
  mainReducers: mainReducers
});

const configureStore = () => {
  return createStore(rootReducer)
}

export default configureStore
