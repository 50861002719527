import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import ScanScreen from './pages/scanScreen';
import LoginScreen from './pages/loginScreen?v5';
import MenuScreen from './pages/menuScreen?v5';
import CompletedScreen from './pages/completedScreen?v5';
import OrdersScreen from './pages/ordersScreen?v5';
import ArchiveScreen from './pages/archiveScreen?v5';
import AccountScreen from './pages/accountScreen?v5';

import './assets/styles/main.css?v5';
import './assets/styles/text.css?v5';

function App() {

    return (

      <Router>
        <Switch>
          <Route exact path='/' component={MenuScreen} />
          <Route path='/scnqr' component={ScanScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/completed' component={CompletedScreen} />
          <Route path='/orders' component={OrdersScreen} />
          <Route path='/archive' component={ArchiveScreen} />
          <Route path='/account' component={AccountScreen} />
        </Switch>
      </Router>
    )
}

export default App;
