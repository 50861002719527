import {
  SET_MODAL_STATE,
  SET_MODAL_TYPE,
  SET_MODAL_DATA,
  SET_ORDER_LIST,
  SET_SUM,
  SET_MENU_STATE
} from './types';

export const setModalState = (modalState) => ({
  type: SET_MODAL_STATE,
  payload: modalState
})

export const setModalType = (modalType) => ({
  type: SET_MODAL_TYPE,
  payload: modalType
})

export const setModalData = (modalData) => ({
  type: SET_MODAL_DATA,
  payload: modalData
})

export const setOrderList = (orderList) => ({
  type: SET_ORDER_LIST,
  payload: orderList
})

export const setSum = (sum) => ({
  type: SET_SUM,
  payload: sum
})

export const setMenuState= (menuState) => ({
  type: SET_MENU_STATE,
  payload: menuState
})
