import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App?v5';
import { Provider } from 'react-redux';
import configureStore from './redux/store';

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  rootElement
)
