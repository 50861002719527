import React, { Component } from 'react';
import '../../assets/styles/modal.css'

import { connect } from 'react-redux';
import { setModalState, setModalData, setModalType, setOrderList } from '../../redux/actions/mainActions';

import plus from '../../assets/media/plus.png';
import minus from '../../assets/media/minus.png';
import close from '../../assets/media/close.png';


class AddToCartModal extends Component {

  state = {
    qty: 1,
    modifying: false,
    index: -1
  }

  componentDidMount() {
    let orders = this.props.orderList
    const { modalData } = this.props;
    let id = orders.find(x => x.key === modalData.key)
    let index = orders.indexOf(id)
    if(id) {
      this.setState({qty: id.qty, modifying: true, index: index})
    }
  }

  closeModal() {
    this.props.setModalData([])
    this.props.setModalState(false)
  }

  addToOrder() {
    const { qty, modifying, index } = this.state;
    const { modalData } = this.props;
    let orders = this.props.orderList
    let key = modalData.key
    let title = modalData.title;
    let price = modalData.price;
    let total = (qty * Number(modalData.price)).toFixed(2)
    let newOrder = {key, title, price, qty, total}
    if(modifying) {
      orders[index] = newOrder;
      this.props.setOrderList(orders)
    } else {
      let newOrderList = orders.concat(newOrder)
      this.props.setOrderList(newOrderList)
    }
    this.props.setModalState(false)
  }

  render() {

    const { qty } = this.state;

    const { modalData } = this.props;

    return (
      <div class='modalWrapper'>
        <div class='modalWrapper bg'>
        </div>
        <div class='modalContainer'>

          <div class='modalHeader'>
            <h1 class='white'>Lisa tellimusse</h1>
            <img src={close} class='closeButton' onClick={() => this.closeModal()}/>
          </div>

          <div class='modalTitle'>
            <h2>{modalData.title}</h2>
          </div>

          <div class='foodDescription'>
            <h4>Toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus</h4>
          </div>

          <div class='orderContainer'>
            <div class='qtyRow'>
              <h2>Kogus:</h2>
              <div class='qtySettingContainer'>
                <img src={plus} class='qtyButton' onClick={() => this.setState({qty: qty + 1})}/>
                <div class='qtyNumberContainer'><h1>{qty}</h1> </div>
                <img src={minus} class='qtyButton' onClick={() => qty > 0 ? this.setState({qty: qty - 1}) : null}/>
              </div>
            </div>
            <div class='qtyRow'>
              <h2>Summa:</h2>
              <div class='qtySettingContainer'>
                <h1 class='red'>{(qty * Number(modalData.price)).toFixed(2)} €</h1>
              </div>
            </div>
            <div class='addToCartButton' onClick={() => this.addToOrder()}>
              <h4 class='strong-white'>Lisa tellimusse</h4>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    modalData: state.mainReducers.modalData,
    orderList: state.mainReducers.orderList
  }
}

export default connect(mapStateToProps, { setModalState, setModalType, setModalData, setOrderList } )(AddToCartModal);
