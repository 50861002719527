// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC21OsBsBXjSm1Al7xWq4-LRDo4eZKSUyI",
  authDomain: "tablesmart-c951e.firebaseapp.com",
  projectId: "tablesmart-c951e",
  storageBucket: "tablesmart-c951e.appspot.com",
  messagingSenderId: "905769872035",
  appId: "1:905769872035:web:956daf1c839a43233fed16",
  measurementId: "G-BEHGEDYN1P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();
export const db = getDatabase();

export function userIdentity() {
  let user = auth.currentUser;
  if(user === null) {
    return 'guest'
  } else {
    return user.uid
  }
}
