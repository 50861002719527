import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ref, query, onValue, orderByChild, equalTo } from "firebase/database";

import { auth, db, userIdentity } from '../assets/services/firebase';

import UserMenu from '../components/userMenu';
import Header from '../components/header';
import Loader from '../components/loader';

import '../assets/styles/archive.css';

import empty from '../assets/media/empty-male.png';

class OrdersScreen extends Component {

  componentDidMount() {
    this.getArchive()
  }

  state = {
    isLoading: true,
    orders: []
  }

  getArchive() {

    const req = query(ref(db, 'archive/' + userIdentity()), orderByChild('done'), equalTo(false));
    onValue(req, (snap) => {
      let orders = []
      snap.forEach(child => {
        orders.push({
          orderDate: child.val().orderDate,
          orderTime: child.val().orderTime,
          orderList: child.val().orderList,
          shopName: child.val().shopName,
          total: child.val().total
        })
      })
      this.setState({orders: orders, isLoading: false})
    })
  }

  render() {

    const { isLoading, orders } = this.state;
    const { menuState } = this.props;

    if(isLoading) {
      return (
        <Loader />
      )
    }

    return (
      <div class='mainContainer'>
        {menuState && <UserMenu />}
        <Header />
        <div class='archiveContainer'>
          <div class='columnHeaderContainer'>
            <h2 class='red'>Aktiivsed tellimused</h2>
            <h4>Siin on sinu aktiivsed tellimused, mis on peagi valmimas</h4>
          </div>
          {orders.length == 0 &&
            <div class='notFoundWrapper'>
              <img src={empty} class='bigImage' />
              <h3 class='light'>Aktiivsed tellimused puuduvad</h3>
            </div>
          }
          {orders.map((item, i) => {
            return (
              <div class='archiveItem'>
                <div class='itemHeader'>
                  <h2>{item.shopName}</h2>
                  <h5>{item.orderDate} @ {item.orderTime}</h5>
                </div>
                {item.orderList.map((title, i) => {
                  return (
                    <div class='itemTitles'>
                      <div class='orderItem'>
                        <div class='itemName'>
                          <div class='row'>
                            <h5>{title.qty}x </h5>
                            <h4>{title.title}</h4>
                          </div>
                        </div>
                        <div class='itemPrice'>
                          <h4 class='strong-black'>{(Number(title.qty) * Number(title.price)).toFixed(2)} €</h4>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div class='itemFooter'>
                  <h2 class='red'>Kokku</h2>
                  <h2 class='red'>{(item.total).toFixed(2)} €</h2>
                </div>
              </div>
            )
          })}

        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    menuState: state.mainReducers.menuState
  }
}

export default connect(mapStateToProps)(OrdersScreen)
