import React from 'react';
import { FallingLines } from 'react-loader-spinner'

import logo from '../assets/media/logo.png'

export default class Loader extends React.Component {

   render() {
    return(
      <div class='bigContainer'>
        <img src={logo} class='logoImage'/>
        <FallingLines
          color="#B70015"
          width="100"
          visible={true}
          ariaLabel='falling-lines-loading'
        />
        <h1>Palun oota...</h1>
      </div>
    );
   }
}
