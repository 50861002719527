import {
  SET_MODAL_STATE,
  SET_MODAL_TYPE,
  SET_MODAL_DATA,
  SET_ORDER_LIST,
  SET_SUM,
  SET_MENU_STATE
} from '../actions/types';

const initialState = {
  modalState: false,
  modalType: '',
  modalData: [],
  orderList: [],
  sum: 0,
  menuState: false
}

const mainReducers = (state = initialState, action) => {
  switch(action.type) {
    case SET_MODAL_STATE:
      return {
        ...state,
        modalState: action.payload
      };
    case SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload
      };
    case SET_MODAL_DATA:
      return {
        ...state,
        modalData: action.payload
      };
    case SET_ORDER_LIST:
      return {
        ...state,
        orderList: action.payload
      };
    case SET_SUM:
      return {
        ...state,
        sum: action.payload
      };
    case SET_MENU_STATE:
      return {
        ...state,
        menuState: action.payload
      };
    default:
      return state
  }
}

export default mainReducers
