import React from 'react';
import notFound from '../assets/media/404-not-found.png'
import logo from '../assets/media/logo.png'

function NotFound() {
  return (
    <div class='bigContainer'>
      <img src={logo} class='logoImage'/>

      <img src={notFound} class='bigContainerImage' />
      <h1>Söögikohta ei leitud</h1>
    </div>
  )
}

export default NotFound
