import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { set, ref, query, onValue } from "firebase/database";

import { auth, db, userIdentity } from '../assets/services/firebase';
import { date, time } from '../assets/services/dateTime';

import Loader from '../components/loader';
import Header from '../components/header';
import UserMenu from '../components/userMenu';
import NotFound from '../components/notFound';
import AddToCartModal from '../components/modals/addToCartModal';

import {
  setModalState,
  setModalData,
  setModalType,
  setSum,
} from '../redux/actions/mainActions';

import search from '../assets/media/search.png';
import arrow from '../assets/media/arrow.png';
import notFound from '../assets/media/notFound.png';

class MenuScreen extends Component {

  state = {
    menuTitle: 0,
    isLoading: true,
    searchTerm: '',
    orderList: [],
    menuOpen: false,
    shopID: ''
  }

  componentDidMount() {
    const shopID = window.localStorage.getItem('pid')
    this.setState({shopID: shopID}, function() {
      this.getMenu()
    })
  }

  getMenu() {
    const { shopID } = this.state;
    const req = query(ref(db, 'menu/' + shopID));
    const titles = query(ref(db, 'menuItems/' + shopID));
    const data = query(ref(db, 'shops/' + shopID));
    onValue(req, (snap) => {
      let menuItems = []
      snap.forEach(child => {
        menuItems.push({
          key: child.key,
          title: child.val().title,
          price: Number(child.val().price),
          category: child.val().category
        })
      })
      this.setState({menuItems: menuItems})
    })
    onValue(data, (snap) => {
      this.setState({shopName: snap.val().shopName, shopLogo: snap.val().logo})
    })
    onValue(titles, (snap) => {
      let menuTitles = []
      snap.forEach(child => {
        menuTitles.push(child.val())
      })
      this.setState({menuTitles: menuTitles, isLoading: false})

    })
  }

  returnMenu() {
    const { menuItems, menuTitle } = this.state;
    const { orderList } = this.props;
    let menu = []
    menuItems.map((item, index) => {
      if(item.category == menuTitle.toString()) {
        let id = orderList.find(x => x.key === item.key)
        let qty
        if(typeof id === 'undefined') {
          qty = 0
        } else {
          qty = id.qty
        }
        menu.push(
          <div class='menuItemWrapper' onClick={() =>this.openModal(item)}>
            <div class='menuItemHeader'>
              <div class='menuItemHeaderName'>
                <div class='headerTitleWrapper'>
                  {qty > 0 &&
                  <div class='headerTitleBulb'><h4 class='strong-white'>{qty}</h4></div>
                  }
                  <h2>{item.title}</h2>
                </div>
              </div>
              <div class='menuItemHeaderPrice'>
              <h2 class='red'>{item.price.toFixed(2)} €</h2>
              </div>
            </div>
            <h4>Toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus</h4>
            <div></div>
          </div>
        )
      }
    })
    return menu
  }

  openModal(item) {
    this.props.setModalData(item)
    this.props.setModalState(true)
  }

  returnSearch() {
    const { menuItems, menuTitle, menuTitles, searchTerm } = this.state;
    const { orderList } = this.props;
    let menu = []
    menuItems.map((item, index) => {
      if(item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        let id = orderList.find(x => x.key === item.key)
        let qty
        if(typeof id === 'undefined') {
          qty = 0
        } else {
          qty = id.qty
        }
        menu.push(
          <div class='menuItemWrapper' onClick={() => this.openModal(item)}>
            <div class='menuItemHeader'>
              <div class='menuItemHeaderName'>
              <div class='headerTitleWrapper'>
                {qty > 0 &&
                <div class='headerTitleBulb'><h4 class='strong-white'>{qty}</h4></div>
                }
                <h2>{item.title}</h2>
              </div>
                <h4 class='strong-red'>{menuTitles[Number(item.category)]}</h4>

              </div>
              <div class='menuItemHeaderPrice'>
              <h2 class='red'>{item.price.toFixed(2)} €</h2>
              </div>
            </div>
            <h4>Toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus, toidu kirjeldus</h4>
            <div></div>
          </div>
        )
      }
    })
    if(menu.length == 0) {
      return (
        <div class='notFoundWrapper'>
          <img src={notFound} class='headerLogo' />
          <h3 class='light'>Kahjuks sellist toitu meie menüüs veel ei ole</h3>
        </div>
      )
    } else {
      return menu
    }
  }

  toggleShowCart() {
    this.setState(prevState => ({menuOpen: !prevState.menuOpen}))
    const { menuOpen } = this.state;
    let state = document.getElementById('cart').style.animationName;
    if(menuOpen) {
      document.getElementById('infoBulb').style.transform = "rotate(180deg)"
      document.getElementById('cart').style.animationName = 'rollUp';
      document.getElementById('cartListWrapper').style.display = 'flex';
    } else {
      document.getElementById('infoBulb').style.transform = "rotate(0deg)"
      document.getElementById('cart').style.animationName = 'rollDown';
      document.getElementById('cartListWrapper').style.display = 'none';
    }
  }

  sendCartToDb() {
    const { shopID } = this.state;
    const { orderList, sum } = this.props;
    const orderID = (new Date().getTime() + Math.floor(Math.random() * 10000) + 9999).toString();
    set(ref(db, 'pending/' + orderID), {
      orderID: orderID,
      orderTimestamp: new Date().getTime(),
      orderDate: date,
      orderTime: time,
      table: '6',
      orderList: orderList,
      shopID: shopID,
      shopName: 'Püssirohu kelder',
      total: sum,
      userPhone: auth.currentUser.phoneNumber,
      userID: userIdentity()
    })

    axios({
      method: 'POST',
      url: 'https://us-central1-tablesmart-c951e.cloudfunctions.net/montonioPayment',
      data: {
        orderID: orderID,
        amount: sum,
      }
    }).then(response => {
      window.location.replace('https://sandbox-payments.montonio.com?payment_token=' + response.data.token)
    })
  }

  returnSum() {
    let orderList = this.props.orderList
    let sum = 0
    orderList.map((item, i) => {
      sum = sum + Number(item.total)
    })
    this.props.setSum(sum)
    return (
      <div class='row dashedUp'>
        <h2 class='white'>Kuulub tasumisele:</h2>
        <h2 class='white'>{sum.toFixed(2)} €</h2>
      </div>
    )
  }

  render() {

    const { isLoading, menuTitle, menuTitles, menuItems, searchTerm, shopName, shopLogo } = this.state;
    const { modalState, modalData, orderList, sum, menuState } = this.props;

    if(isLoading) {
      return (
        <Loader />
      )
    }

    if(menuTitles.length == 0) {
      return (
        <NotFound />
      )
    }

    return (
      <div class='mainContainer'>
        {menuState && <UserMenu />}
        {modalState && <AddToCartModal data={modalData} /> }

        <Header shopName={shopName} shopLogo={shopLogo} showClient={true}/>
        <div class='searchWrapper'>
          <input class='input' placeholder='Otsi menüüst' onChange={(event) => this.setState({searchTerm: event.target.value})}/>
          <img src={search} class='searchIcon'/>
        </div>
        {searchTerm.length < 3 &&
        <div class='menuTitlesRow'>
          {menuTitles.map((item, i) => {
            return (
              <div key={i} class='menuTitleItem' onClick={() => this.setState({menuTitle: i})}>
                <h4 class={menuTitle == i ? 'strong-red' : 'strong-gray'}>{item}</h4>
              </div>
            )
          })}
        </div>
        }
        <div class='menuWrapper'>
          {searchTerm.length > 2 ?
            this.returnSearch()
          :
            this.returnMenu()
          }
        </div>
        <div class='cartWrapper' id='cart'>
          <div class='cartInfoBulb' onClick={() => this.toggleShowCart()}>
            <img src={arrow} class='smallIcon' id='infoBulb'/>
          </div>
          {orderList.length < 1 ?
          <h4 class='strong-white'>Tellimusse pole veel midagi lisatud</h4>
          :
          <div class='cartListWrapper' id='cartListWrapper'>
            <div class='row dashed'>
              <h2 class='white'>Sinu tellimus</h2>
            </div>
            <div class='cartItemsContainer'>
            {orderList.map((item, i) => {
              if(item.total !== '0.00') {
                return (
                  <div key={i} class='cartItem'>
                    <div class='row'>
                      <h4 class='white'>{item.qty}x</h4>
                      <h4 class='strong-white'>{item.title}</h4>
                    </div>
                    <div class='row'>
                      <h4 class='white-total'>{item.qty}x {item.price.toFixed(2)} €</h4>
                      <h4 class='strong-white'>{item.total} €</h4>
                    </div>
                  </div>
                )
              }
            })}
            </div>
            {this.returnSum()}
            <div class='fullBtn' id='confirmOrderBtn' onClick={() => this.sendCartToDb()}>
              <h2 class='white'>Vormista tellimus</h2>
            </div>

          </div>
          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    modalState: state.mainReducers.modalState,
    modalData: state.mainReducers.modalData,
    modalType: state.mainReducers.modalType,
    orderList: state.mainReducers.orderList,
    sum: state.mainReducers.sum,
    menuState: state.mainReducers.menuState
  }
}

export default connect(mapStateToProps, { setModalState, setModalData, setModalType, setSum})(MenuScreen)
