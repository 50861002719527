import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { setMenuState } from '../redux/actions/mainActions';

import '../assets/styles/userMenu.css';
import close from '../assets/media/close_white.png';

const shopID = window.localStorage.getItem('pid')

const items = [
  {key: 0, title: 'Tellimine', page: '/'},
  {key: 1, title: 'Aktiivsed tellimused', page: '/orders'},
  {key: 2, title: 'Tellimuste ajalugu', page: '/archive'},
  {key: 3, title: 'Konto', page: '/account'},
]

class UserMenu extends Component {

  render() {
    return (
      <div class='userMenuWrapper'>
        <img src={close} class='menuCloseImg' onClick={() => this.props.setMenuState(false)}/>
        {items.map(item => {
          return (
            <NavLink to={{pathname: item.page, state: {shopID: shopID}}} key={item.key} class='userMenuItemWrapper' onClick={() => this.props.setMenuState(false)}>
              <h1 class='white'>{item.title}</h1>
            </NavLink>
          )
        })}
      </div>
    )
  }
}

export default connect(null, { setMenuState } )(UserMenu);
