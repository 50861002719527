import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setMenuState } from '../redux/actions/mainActions';
import { auth } from '../assets/services/firebase';

import '../assets/styles/header.css'

import menu from '../assets/media/menu.png';
import logo from '../assets/media/logo.png';

class Header extends Component {

  componentDidMount() {
    auth.onAuthStateChanged(firebaseUser => {
      if(!firebaseUser){
        window.location = '/login';
      }
    });
  }

  render() {
    return (
      <div class='headerWrapper'>

        <div class='headerRow'>
          <img src={menu} class='smallIcon border' onClick={() => this.props.setMenuState(true)}/>
          <img src={logo} class='logoImage'/>
        </div>

        {this.props.showClient &&
        <div class='headerColumn'>
          <div class='headerRow flexEnd'>
            <img src={this.props.shopLogo} class='headerLogo' />
          </div>
          <div class='headerRow'>
            <h1>{this.props.shopName}</h1>
          </div>
        </div>
        }
      </div>
    )
  }

}

export default connect(null, { setMenuState })(Header)
