import React, { Component } from 'react';
import Loader from '../components/loader';

class ScanScreen extends Component {

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const shopID = query.get('pid');
    window.localStorage.setItem('pid', shopID);
    window.location = '/';
  }

  render() {
    return (
      <Loader />
    )
  }
}

export default ScanScreen;
